<template>
  <div v-if="$store.getters.ready">
    <Header nav="profile" active="issues" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix mb-3">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">Issue Activity</h5>
            </div>
          </div>

          <div class="widget-list">

            <div class="row">
              <div class="col-md-3 pr-3 d-none d-md-block">
                <h6 class="box-title mb-3">Activity Log</h6>
                <p>A complete log of recently worked on issues.</p>
              </div>
              <div class="col-md-9">
                <div v-for="(m, index) in months" :key="m">
                  <div class="widget-holder mb-0">
                    <div class="widget-bg">
                      <div class="widget-body">
                        <div class="row">
                          <div class="col">
                            <h5 class="mt-2 pt-1">{{ $datefns.utc.format(m, 'MMMM yyyy') }}</h5>
                          </div>
                          <div class="col text-right">
                            <a :href="`${$api.defaults.baseURL}/profile/billing/issues/${$datefns.utc.format(m, 'yyyy-MM')}`" class="btn btn-default btn-rounded" role="button"><i class="fal fa-print mr-2"></i>Print</a>
                          </div>
                        </div>
                        <hr>
                        <table class="table">
                          <thead><tr><th style="width: 180px;">Project</th><th style="width: 90px;">Issue</th><th>Description</th></tr></thead>
                          <tbody>
                            <tr v-for="t in issuesMonthGroup(m)" :key="t.id">
                              <td>{{ t.project }}</td>
                              <td>{{ t.issue }}</td>
                              <td>{{ t.description }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-if="index < months.length - 1">
                    <div class="issuedetails-connect"></div>
                    <div v-if="$datefns.formatDistance(new Date(months[index + 1]), new Date(m)).includes('year') || ($datefns.formatDistance(new Date(months[index + 1]), new Date(m)).includes('month') && $datefns.formatDistance(new Date(months[index + 1]), new Date(m)) != '1 month')">
                      <div class="clearfix issuedetails-action">
                        <div class="float-left issuedetails-action-icon"><i class="fal fa-clock fa-fw text-muted rounded-circle"></i></div>
                        <div class="float-right issuedetails-action-description text-muted pt-2 mt-1">No activity for {{ $datefns.formatDistance(new Date(months[index + 1]), new Date(m)) }}</div>
                      </div>
                      <div class="issuedetails-connect"></div>
                    </div>
                  </div>
                </div>
                <div v-if="issues.length === 0" class="widget-holder">
                  <div class="widget-bg">
                    <div class="widget-body">
                      <div class="text-center py-4">
                        <h4 class="my-0"><p><i class="fal fa-clipboard-list fa-5x text-stroke-5 text-icon-gray"></i></p><p>No issue activity found.</p></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/AccountHeader.vue';

export default {
  data() {
    return {
      issues: [],
    };
  },
  computed: {
    months() {
      const m = [];
      for (let i = 0; i < this.issues.length; i += 1) {
        m.push(this.issues[i].created_at.substring(0, 7));
      }
      return Array.from(new Set(m));
    },
  },
  methods: {
    issuesMonthGroup(m) {
      const t = [];
      for (let i = 0; i < this.issues.length; i += 1) {
        if (this.issues[i].created_at.startsWith(m)) {
          t.push(this.issues[i]);
        }
      }
      return t;
    },
  },
  async mounted() {
    if (!this.$auth.profile.enable_billing) {
      this.$store.commit('error', 404);
      return;
    }
    try {
      this.issues = (await this.$api.get('/profile/billing/issues')).data;
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
  },
};
</script>
